import React, { useEffect } from 'react';
import { createMaterialBottomTabNavigator } from 'react-native-paper/react-navigation';
import Therapists from './therapists/Therapists';
import { Icon, useTheme } from 'react-native-paper';
import Appointments from './Appointments/Appointments';
import { useMe } from '../../core/api/me';

export type PagesNavParamList = {
    "Therapists": undefined,
    "Appointments": undefined
};

const Tab = createMaterialBottomTabNavigator<PagesNavParamList>();
const Pages: React.FC = () => {

    const theme = useTheme();

    const me = useMe();

    useEffect(() => {
        me.fetchMyProfile();
    }, []);

    return (
        <Tab.Navigator id='PageNavigator'
            initialRouteName="Therapists"
            barStyle={{
                backgroundColor: theme.colors.surface,
                borderTopColor: theme.colors.surfaceVariant,
                borderTopWidth: 1
            }}>

            <Tab.Screen
                name="Therapists"
                component={Therapists}
                options={{
                    tabBarLabel: 'Inicio',
                    tabBarIcon: ({ focused, color }) => (
                        <Icon source={focused ? "account-heart" : "account-heart-outline"} color={color} size={26} />
                    )
                }}
            />

            <Tab.Screen
                name="Appointments"
                component={Appointments}
                options={{
                    tabBarLabel: 'Citas',
                    tabBarIcon: ({ focused, color }) => (
                        <Icon source={focused ? "calendar-blank" : "calendar-blank-outline"} color={color} size={26} />
                    )
                }}
            />
        </Tab.Navigator>
    );
}

export default Pages;
